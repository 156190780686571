import request from '@/utils/request'


// 买家中心添加签署人
export function add(data) {
    return request({
        url: '/trade/buyer/add',
        method: 'post',
        data
    })
}
// 买家中心添加签署人编辑
export function updatesignerpurchase(data) {
    return request({
        url: '/trade/buyer/updatesignerpurchase',
        method: 'post',
        data
    })
}
//买家中心添加关联
export function findsupplierall(params) {
    return request({
        url: '/trade/buyer/findsupplierall',
        method: 'get',
        params
    })
}
//买家中心添加签署人关联卖家
export function relevancysup(data) {
    return request({
        url: '/trade/buyer/relevancysup',
        method: 'post',
        data
    })
}
// 买家签署人解除与卖家关联
export function updateflag(params) {
    return request({
        url: '/trade/buyer/updateflag',
        method: 'get',
        params
    })
}

// 买家中心签署人
export function signset(params) {
    return request({
        url: '/trade/buyer/getsellerlist',
        method: 'get',
        params
    })
}
// 主体信息
export function subject(params) {
    return request({
        url: '/trade/pur/subject',
        method: 'get',
        params
    })
}
//买家签署人列表
export function signtory(params) {
    return request({
        url: '/trade/pur/signtory',
        method: 'get',
        params
    })
}

//买家签署人删除
export function delsignerpurchaser(params) {
    return request({
        url: '/trade/buyer/delsignerpurchaser',
        method: 'get',
        params
    })
}
//修改默认签署人
export function updatedefaultsign(data) {
    return request({
        url: '/trade/buyer/updatedefaultsign',
        method: 'post',
        data
    })
}

//权限管理 子账号编辑 
export function updatepartycontrolentity(data) {
    return request({
        url: '/trade/buyer/updatepartycontrolentity',
        method: 'post',
        data
    })
}
// 公章列表
export function list(params) {
    return request({
        url: '/trade/buyer/seal/list',
        method: 'get',
        params
    })
}
// 设置默认公章
export function setdefaultseal(data) {
    return request({
        url: '/trade/buyer/seal/set/default',
        method: 'post',
        data
    })
}
// 删除公章
export function deleteseal(data) {
    return request({
        url: '/trade/buyer/seal/delete',
        method: 'post',
        data
    })
}
// 上传公章
export function uploadseal(data) {
    return request({
        url: '/trade/buyer/seal/add',
        method: 'post',
        data
    })
}

// 批量订单任务列表
export function batchList(params) {
    return request({
        url: '/order/batch/list',
        method: 'get',
        params
    })
}

// 添加批量订单任务
export function batchAdd(data) {
    return request({
        url: '/order/batch/add',
        method: 'post',
        data
    })
}

// 下载批量订单的模板
export function batchDownload(purchaserId) {
    return request({
        url: `/order/batch/template/download/${purchaserId}`,
        method: 'get'
    })
}

// 下游管理-列表
export function dsList(params) {
    return request({
        url: '/trade/purchaser/ds/list',
        method: 'get',
        params
    })
}

// 下游管理-获取下游企业列表
export function downstreamList(params) {
    return request({
        url: '/freight/warehouse/in/downstream/list',
        method: 'get',
        params
    })
}

// 下游管理-添加信息
export function dsAdd(data) {
    return request({
        url: '/trade/purchaser/ds/add',
        method: 'post',
        data
    })
}

// 下游管理-更新下游信息
export function dsUpdate(data) {
    return request({
        url: '/trade/purchaser/ds/update',
        method: 'post',
        data
    })
}

// 下游管理-更新启用状态
export function dsStatus(data) {
    return request({
        url: '/trade/purchaser/ds/status/update',
        method: 'post',
        data
    })
}

// 下游管理-根据买家id获取名下启用的下游信息
export function dsPur(id) {
    return request({
        url: '/trade/purchaser/ds/pur/' + id,
        method: 'get'
    })
}

// 签署设置-签署人个体
export function signtoryInfo(params) {
    return request({
        url: '/trade/pur/signtory/info',
        method: 'get',
        params
    })
}

// 买家设置默认签署人
export function signtorySet(data) {
    return request({
        url: '/trade/buyer/signtory/default',
        method: 'post',
        data
    })
}


//权限管理 小程序 添加员工
export function xcxAppListAdd(data) {
    return request({
        url: `/trade/purchaser/ds/app/user/add`,
        method: 'post',
        data
    })
}

//权限管理 小程序 删除用户
export function xcxAppListDelete(appUserId) {
    return request({
        url: `/trade/purchaser/ds/app/user/delete/${appUserId}`,
        method: 'post'
    })
}

//权限管理 小程序 更新员工
export function xcxAppListUpdata(data) {
    return request({
        url: `/trade/purchaser/ds/app/user/update`,
        method: 'post',
        data
    })
}

//权限管理 小程序 角色列表
export function roleList() {
    return request({
        url: `/trade/purchaser/ds/app/role/list`,
        method: 'get'
    })
}

//权限管理 小程序用户列表 
export function xcxAppList(purchaserId) {
    return request({
        url: `/trade/purchaser/ds/app/user/list/${purchaserId}`,
        method: 'get'
    })
}

//权限管理 库存管理列表
export function warehouseList(data) {
    return request({
        url: `/freight/warehouse/in/list`,
        method: 'get',
        params:data
    })
}

//库存管理 获取对应类别列表
export function categorysList(params) {
    return request({
        url: `/freight/warehouse/in/categorys`,
        method: 'get',
        params
    })
}

//库存管理 获取对应供应商列表
export function suppliersList(params) {
    return request({
        url: `/freight/warehouse/in/suppliers`,
        method: 'get',
        params
    })
}

//库存管理 入库详情
export function warehouseInfo(id) {
    return request({
        url: `/freight/warehouse/in/info/${id}`,
        method: 'get'
    })
}

//权限管理 删除库存
export function warehouseDelete(id) {
    return request({
        url: `/freight/warehouse/in/delete/${id}`,
        method: 'post'
    })
}