<template>
  <div class="fillOrder">
    <div class="fillOrder-title"><b>填写订单信息</b></div>
    <div class="mainbody">
      <a-card>
        <a-row type="flex" align="middle" class="lh60">
          <a-col :span="24">
            <a-space :size="24">
              <span> 下游管理 </span>
              <a-select v-model="confirm.downstreamId" style="width: 320px" :dropdownMatchSelectWidth="false">
                <a-select-option :key="null">
                  无特定下游
                </a-select-option>
                <a-select-option v-for="item in dsList" :key="item.id">
                  {{item.downstreamName}}
                </a-select-option>
              </a-select>
            </a-space>
          </a-col>
          <a-col :span="24"> 收货地址 </a-col>
        </a-row>
        <a-row type="flex" class="bd1" align="middle"
          :class="!updateinfo.detailAddr && !updateinfo.tagAddr && bord ? 'warn' : ''">
          <div class="address">
            <a-col :span="3" class="color999">地址标签：</a-col>
            <a-col :span="21">{{ updateinfo.tagAddr }}&nbsp;</a-col>
            <a-col :span="3" class="color999">收货地址：</a-col>
            <a-col :span="21">{{ updateinfo.fullAddr }}</a-col>
          </div>
          <div class="addr" @click="showModal">
            <span class="addr-text">修改地址</span>
          </div>
          <div class="line"></div>
          <div class="switch-address">
            <a-button type="primary" class="w140" @click="switchfill = true">
              <a-icon type="environment" />切换地址
            </a-button>&nbsp;&nbsp;
            <a-button type="primary" class="w140" @click="addfill = true">
              <a-icon type="plus-circle" />添加地址
            </a-button>
          </div>
        </a-row>
      </a-card>
      <p class="red pt5" v-if="!updateinfo.detailAddr && !updateinfo.tagAddr && bord">
        请填写收货地址
      </p>
      <a-row type="flex" align="middle" class="lh60 mt42">
        <a-col :span="24"> 商品信息 </a-col>
      </a-row>

      <a-spin :spinning="spinTop">
      <div class="p-table-title">
        <div class="p-name">供应商商品</div>
        <div class="bill-name">发票名称</div>
        <div class="p-type">规格</div>
        <div class="p-price">单价(不含税)</div>
        <div class="p-number">数量</div>
        <div class="p-tax">金额(不含税)</div>
        <div class="p-tax">增值税</div>
        <div class="p-sub">小计(含税费)</div>
      </div>

      <a-table v-for="(item,index) in data_" :key="index" :columns="
      [{
        title: '供应商：' + item.supplierName,
        dataIndex: 'mdseName',
        width: '20%',
        align: 'left',
        scopedSlots: { customRender: 'p_name' }
      },
      {
        title: '卖家：' + item.legalName + ' ' + item.legalPhone,
        dataIndex: 'invoiceName',
        width: '17%',
      
        scopedSlots: { customRender: 'name' }
      },
      {
        title: '',
        dataIndex: 'specName',
        width: '13%',
        align: 'center'
      },
      {
        title: '',
        dataIndex: 'taxableUnitPrice',
        width: '8%',
        align: 'center'
      },
      {
        title: '单位：' + type[item.unitsMeasure],
        dataIndex: 'quantity',
        width: '8%',
        align: 'center',
        scopedSlots: { customRender: 'name' }
      },
      {
        title: '',
        dataIndex: 'taxableAmount',
        width: '10%',
        align: 'center'
      },
      {
        title: '税率：' + Math.round(item.vatRate * 1000000) / 10000 + '%',
        dataIndex: 'vatTaxAmount',
        width: '10%',
        align: 'center'
      },
      {
        title: '',
        dataIndex: 'totalAmount',
        align: 'center',
        scopedSlots: { customRender: 'p_sub' }
      }]" :rowKey="(row, index) => index" :pagination="false" :dataSource="item.merchandises" class="p_table">
        <template slot="p_name" slot-scope="text, row">
          <a-space :size="12">
            <a-avatar shape="square" class="square" :size="50" :src="row.imgPath || noImg" />
            <div class="goods-name">{{text}}</div>
          </a-space>
          <!-- <span class="">{{text}}</span> -->
        </template>
        <template slot="vatRate" slot-scope="text">
          <span>{{text}}%</span>
        </template>
        <template slot="p_sub" slot-scope="text">
          <span class="p_sub_text">{{text}}</span>
        </template>
        <template slot="footer">
          <div class="table-footer">
            <div class="remark">
              <div class="remark-label">备注：</div>
              <a-textarea v-model="item.remark" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </div>
            <div class="f-total">
              <div class="total-text">店铺合计（含税费）</div>
            </div>
            <div class="total-pic">¥{{item.contractTotalAmount}}</div>
          </div>
        </template>
      </a-table>
      <a-row type="flex" justify="end" align="middle" class="s-total">
        <a-col align="right" style="width: 10%">
          <div class="t-total">合计付款：</div>
        </a-col>
        <a-col align="right">
          <div class="c-total">¥{{indexInfo.totalAmount}}</div>
        </a-col>
      </a-row>
      </a-spin>

      <!-- <a-row class="goods-info">
        <div>
          <a-table :columns="columns" :pagination="false"></a-table>
        </div>
        <a-row class="border-t pt20 pb20">
          <a-col :span="7" align="right"
            ><span>{{ indexInfo.invoiceName }}</span>
            <span v-if="indexInfo.specName"
              >-{{ indexInfo.specName }}
            </span></a-col
          >
          <a-col :span="5" align="right" >{{typeof indexInfo.unitPrice == 'number' && indexInfo.unitPrice.toFixed(2)  }}</a-col>
          <a-col :span="5" align="right"
            ><span>{{ indexInfo.quantity }}</span
            ><span>{{ indexInfo.unitsMeasure | unit }}</span></a-col
          >
          <a-col :span="7" align="right" style="color: red"
            >￥{{ typeof indexInfo.taxableAmount == 'number' &&indexInfo.taxableAmount.toFixed(2) }}</a-col
          >
        </a-row>
      </a-row>
      <div class="l-flex remark">
        <span>备注：</span>
        <a-textarea :maxLength="200" placeholder="请输入备注" style="width: 500px" v-model="confirm.remark" :auto-size="{ minRows: 2, maxRows: 6 }"></a-textarea>
      </div> -->
      <a-row type="flex" align="middle">
        <a-col :span="24" class="lh60 mt42"> 发票信息确认（预览页，以实际拆分开票后为准） </a-col>
        <a-col :span="24" align="center"><span class="invoice-title">增值税电子普通发票</span></a-col>
        <a-col :span="24">
        <a-spin :spinning="spinTop">
          <a-table class="invoiceTable" :rowKey="(row, index) => index" :dataSource="dataInvoice" bordered size="small"
            :columns="columnsInvoice" :pagination="false">
            <span style="writing-mode: vertical-lr;" slot="purchaserTitle">购&#12288;买&#12288;方</span>
            <a-row slot="invoiceTitle" class="table-row">
              <a-col :span="12">
                <span>名&#12288;&#12288;&#12288;&#12288;称：</span>{{purchaserInvoiceInfo.commercialName}}
              </a-col>
              <a-col :span="12" class="table-col">
                <span>电子发票邮箱：</span>{{purchaserInvoiceInfo.invoiceEmail}}
                <a-button type="primary" class="w140" @click="amendinfo = true">
                  <a-icon type="edit" /> 修改发票抬头
                </a-button>
              </a-col>
              <a-col :span="24">
                <span>纳税人识别号：</span>{{purchaserInvoiceInfo.uniformCode}}
              </a-col>
              <a-col :span="24">
                <span>地址、&#12288;电话：</span>{{purchaserInvoiceInfo.invoiceAddr}}&nbsp;
                {{purchaserInvoiceInfo.invoiceMobile}}
              </a-col>
              <a-col :span="24">
                <span>开户行及账号：</span>{{purchaserInvoiceInfo.invoiceBankName}}&nbsp;
                {{purchaserInvoiceInfo.invoiceBankAcc}}
              </a-col>
            </a-row>
          </a-table>
        </a-spin>
        </a-col>
      </a-row>
    </div>
    <!-- <a-row type="flex" align="middle" class="create-order">
      <a-col :span="7">
        卖方：<span class="fw">{{ indexInfo.supplierName }}</span>
      </a-col>
      <a-col :span="13">
        数量：<span class="fw">{{ indexInfo.quantity }}</span
        ><span class="fw">{{ indexInfo.unitsMeasure | unit }}</span>
      </a-col>
      <a-col :span="7"
        >商品金额（不含增值税）：<span class="fw" >{{
          typeof indexInfo.taxableAmount == 'number' && indexInfo.taxableAmount.toFixed(2)
        }}</span>
        <span class="fw">元</span>
      </a-col>
      <a-col :span="13">
        增值税：<span class="fw" >{{ typeof indexInfo.vatTax == 'number' && indexInfo.vatTax.toFixed(2) }}</span>
        <span class="fw">元</span>
      </a-col>
      <a-col :span="4"
        ><a-button
          type="primary"
          class="btn-order"
          :loading="loading"
          @click="produceOrder"
          >生成订单</a-button
        ></a-col
      >
    </a-row> -->
    <a-row type="flex" justify="end" align="middle" class="create-order">
      <a-col :span="24" style="padding-top: 40px" align="center">
        <a-space :size="32">
          <a-button class="btn-w" size="large" @click="$router.back()">返回</a-button>
          <a-button type="primary" size="large" class="btn-w" :loading="loading" @click="produceOrder">生成订单</a-button>
        </a-space>
      </a-col>
    </a-row>
    <!-- 修改地址 -->
    <a-modal :visible="amendfill" cancelText="取消" okText="保存" title="修改收货地址" @ok="amendAddress" :maskClosable="false"
      @cancel="handleCancel1">
      <a-row class="pt10">
        <a-col>地址标签</a-col>
        <a-col class="pt5">
          <a-input v-model="updateinfo.tagAddr" placeholder="给该地址一个别名，便于识别。例如：青岛工厂仓库" />
        </a-col>
      </a-row>
      <a-row class="pt20">
        <a-col>省份/自治区</a-col>
        <a-col></a-col>
        <a-col class="pt5">
          <a-cascader
            :show-search="{ filter:(inputValue, path) => path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) }"
            style="width: 100%" :field-names="{
              value: 'key',
              label: 'label',
              children: 'children'
            }" :options="area" :key="updateinfo.provinceCode" :default-value="[
              updateinfo.provinceCode,
              updateinfo.cityCode,
              updateinfo.areaCode
            ]" placeholder="选择省/市/区" @change="ChangeAmend" />
        </a-col>
      </a-row>
      <a-row class="pt20">
        <a-col>详细地址</a-col>
        <a-col class="pt5">
          <a-textarea v-model="updateinfo.detailAddr" :auto-size="{ minRows: 5, maxRows: 5 }" />
        </a-col>
        <a-col class="pt20">
          <a-checkbox :checked="checked" @change="checked = $event.target.checked">
            默认地址
          </a-checkbox>
        </a-col>
      </a-row>
    </a-modal>
    <!-- 切换地址 -->
    <a-modal :width="600" :visible="switchfill" title="切换收货地址" cancelText="取消" okText="保存" @ok="save"
      :maskClosable="false" @cancel="
        switchfill = false;
        save()
      ">
      <div class="scrollY">
        <div class="ress" v-for="item in listAddress" :key="item.id" :class="item.id === pitchs ? 'bordred' : ''"
          @click="pitch(item)">
          <a-row>
            <a-col :span="4" class="color999">地址标签：</a-col>
            <a-col :span="12">{{ item.tagAddr }}</a-col>
          </a-row>
          <a-row>
            <a-col :span="4" class="color999">收货地址：</a-col>
            <a-col :span="15">{{ item.fullAddr }}</a-col>
            <a-col :span="2" class="cursor">
              <a-popconfirm title="确认删除?" @confirm="() => del(item.id)">
                删除
              </a-popconfirm>
            </a-col>

            <a-col :span="3" aligh="right" v-if="item.isDefault === 1">默认地址</a-col>
            <a-col :span="3" aligh="right" @click="switchAddress(item.id)" v-if="item.isDefault === 0" class="cursor"
              style="color: #60a830">设为默认</a-col>
          </a-row>
        </div>
      </div>
    </a-modal>
    <!-- 添加地址 -->
    <a-modal :visible="addfill" cancelText="取消" okText="保存" title="添加收货地址" :maskClosable="false" @ok="addAddress"
      @cancel="addfill = false;checkedAdd = false">
      <a-row class="pt10">
        <a-col>地址标签</a-col>
        <a-col class="pt5">
          <a-input v-model="addinfo.tagAddr" placeholder="给该地址一个别名，便于识别。例如：青岛工厂仓库" />
        </a-col>
      </a-row>
      <a-row class="pt20">
        <a-col>省份/自治区</a-col>
        <a-col class="pt5">
          <a-cascader
            :show-search="{ filter:(inputValue, path) => path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) }"
            style="width: 100%" :field-names="{
              value: 'key',
              label: 'label',
              children: 'children'
            }" ref="Cascader" :options="area" :default-value="[
              addinfo.provinceCode,
              addinfo.cityCode,
              addinfo.areaCode
            ]" placeholder="选择省/市/区" @change="onChange" />
        </a-col>
      </a-row>
      <a-row class="pt20">
        <a-col>详细地址</a-col>
        <a-col class="pt5">
          <a-textarea v-model="addinfo.detailAddr" :auto-size="{ minRows: 5, maxRows: 5 }" />
        </a-col>
        <a-col class="pt20">
          <a-checkbox :checked="checkedAdd" @change="checkedAdd = $event.target.checked">
            默认地址
          </a-checkbox>
        </a-col>
      </a-row>
    </a-modal>
    <!-- 发票信息 -->
    <a-modal :centered="true" :width="600" :visible="amendinfo" cancelText="取消" okText="保存" :maskClosable="false"
      @ok="invoiceOk" @cancel="amend">
      <a-row>
        <a-col align="center" class="fs16">发票信息</a-col>
      </a-row>
      <a-row>
        <a-col>发票抬头</a-col>
        <a-col class="pt5">
          <a-input disabled v-model="purchaserInvoiceInfo.commercialName"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>纳税人识别号</a-col>
        <a-col class="pt5">
          <a-input disabled v-model="purchaserInvoiceInfo.uniformCode"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票地址</a-col>
        <a-col class="pt5">
          <a-input @input="purchaserInvoiceInfo.invoiceAddr=$event.target.value.replace(/^\s+|\s+$/g,'')"
            v-model="purchaserInvoiceInfo.invoiceAddr"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票电话</a-col>
        <a-col class="pt5">
          <a-input :maxLength="18" v-model="purchaserInvoiceInfo.invoiceMobile"
            @input="purchaserInvoiceInfo.invoiceMobile=$event.target.value.replace(/[^\d|-]/g,'')"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票银行名称</a-col>
        <a-col class="pt5">
          <a-input @input="purchaserInvoiceInfo.invoiceBankName=$event.target.value.replace(/^\s+|\s+$/g,'')"
            v-model="purchaserInvoiceInfo.invoiceBankName"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票银行账号</a-col>
        <a-col class="pt5">
          <a-input :maxLength="30"
            @input="purchaserInvoiceInfo.invoiceBankAcc=$event.target.value.replace(/^\s+|\s+$/g,'')"
            v-model="purchaserInvoiceInfo.invoiceBankAcc"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>电子发票邮箱</a-col>
        <a-col class="pt5">
          <a-input v-model="purchaserInvoiceInfo.invoiceEmail"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票须知：</a-col>
        <a-col>1. 订单付款后纳税人识别号不可修改，请确认信息后付款；</a-col>
        <a-col>2.
          在订单完成后申请开票，纸制发票我方会统一开票后寄给买方，电子发票会通过邮件发送。</a-col>
        <a-col>3. 发票金额为实际支付金额。</a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: '商品信息',
    dataIndex: 'name',
    width: '25%',
    align: 'right',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '单价',
    width: '24%',
    align: 'right',
    dataIndex: 'money'
  },
  {
    title: '数量',
    width: '21%',
    align: 'right',
    dataIndex: 'age'
  },
  {
    title: '商品总价',
    align: 'right',
    width: '27%',
    color: 'red',
    dataIndex: 'pay',
    scopedSlots: { customRender: 'pay' }
  }
]

// var myreg = /^[1][3-9][0-9]{9}$/
const regEmail = /.{1,}@.{1,}\..{1,}/

import {
  confirm,
  list,
  getdefault,
  setdefault,
  createAddress,
  updateAddress,
  deleteAddress,
  createOrder
} from '../../api/order'
import { updateinvoice } from '@/api/seller'
import { dsPur } from '@/api/buyerCenter'
import { cartPreview, cartCreate } from '@/api/cart'
import area from '../../utils/ara'
import { type } from '../../utils/constArr'
import { convertCurrency } from '@/utils/index'
export default {
  data() {
    const columnsInvoice = [
      {
        dataIndex: 'purchaser',
        key: 'purchaser',
        align: 'center',
        slots: { title: 'purchaserTitle' },
        children: [
          {
            dataIndex: 'age',
            align: 'center',
            key: 'age',
            customRender: (value, row, index) => {
              const obj = {
                children: row.config !== 'space' ? index + 1 : (<div style="visibility: hidden;">{index}</div>),
                attrs: {}
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                const filter = {
                  total: '合计',
                  upperCase: '价税合计（大写）'
                }
                obj.attrs.colSpan = 2
                obj.children = filter[row.config]
              }
              return obj
            }
          },
        ],
      },
      {
        slots: { title: 'invoiceTitle' },
        children: [
          {
            title: '货物或应税劳务名称',
            dataIndex: 'invoiceName',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value,
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'invoiceName'
          },
          {
            title: '规格型号',
            dataIndex: 'specName',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value,
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.class = 'font-foot'
                obj.attrs.colSpan = 7
                obj.children = row.value
              }
              return obj
            },
            key: 'specName'
          },
          {
            title: '单位',
            dataIndex: 'unitsMeasure',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: type[value],
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'unitsMeasure'
          },
          {
            title: '数量',
            dataIndex: 'quantity',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value,
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'quantity'
          },
          {
            title: '单价(不含税)',
            dataIndex: 'taxableUnitPrice',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value,
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'unitPrice'
          },
          {
            title: '金额(不含税)',
            dataIndex: 'taxableAmount',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value,
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'taxableAmount'
          },
          {
            title: '税率',
            dataIndex: 'vatRate',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value !== undefined && Math.round(value * 1000000) / 10000 + '%',
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'vatRate'
          },
          {
            title: '税额',
            dataIndex: 'vatTaxAmount',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value !== undefined && value.toFixed(2),
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'vatTax'
          }
        ],
      }
    ]
    return {
      type,
      data_: [],
      noImg: require('@/assets/images/noImg.png'),
      area,
      columns,
      columnsInvoice,
      confirm: {
        merchandiseId: Number(this.$route.query.merchandiseId),
        purchaserId: Number(this.$route.query.purchaserId),
        taxRegionPolicyId: Number(this.$route.query.taxRegionPolicyId),
        quantity: Number(this.$route.query.quantity),
        grossAmount: Number(this.$route.query.grossAmount),
        remark: null,
        downstreamId: null
      },
      checked: false,
      checkedAdd: false,
      updateinfo: {
        //修改地址
        addrId: null, //收获ID
        provinceCode: '', //省份编码
        areaCode: '', //区域编码
        cityCode: '', //城市编码
        detailAddr: '', //详情地址
        tagAddr: '' //地址标签
      },
      listAddress: [],
      addinfo: {
        //添加地址
        purchaserId: this.$route.query.purchaserId, //收获ID
        provinceCode: '', //省份编码
        areaCode: '', //区域编码
        cityCode: '', //城市编码
        detailAddr: '', //详情地址
        tagAddr: '' //地址标签
      },
      indexInfo: {
        //页面数据
        merchandiseId: null, //商品id
        supplierName: '', //卖家名称
        invoiceName: '', //开票名称
        invoiceCode: '', //开票编码
        specName: '', //规格型号
        unitPrice: null, //单价
        quantity: '', //采购数量
        unitsMeasure: null, //单位
        taxableAmount: null, //应税总价--不含增值税的总价
        amountWithTax: null, //含税总价
        vatTax: null, //增值税金额
        vatRate: null, //增值税税率
        addressDtoList: [],
        purchaserInvoiceInfo: {}
      },
      pitchs: undefined,
      purchaserInvoiceInfo: {
        id: this.$route.query.purchaserId, //买家id
        commercialName: '', //主体名称，发票抬头
        uniformCode: '', //证件号：企业统一信用代码
        invoiceAddr: '', //发票地址
        invoiceMobile: '', //发票电话
        invoiceBankName: '', //发票银行开户行
        invoiceBankAcc: '', //发票银行账号
        invoiceEmail: '' //电子发票投递邮箱
      },
      amendfill: false, //修改
      switchfill: false, //切换
      addfill: false, //添加
      amendinfo: false, //修改信息,
      bord: false, //警告框
      bord2: false, //警告框
      loading: false,
      invoiceText: '',
      dsList: [], // 下游管理列表
      dataInvoice: [],
      cartConfig: {}, // 购物车生成订单参数
      spinTop: false
    }
  },
  created() {
    if (this.$route.query.type == 'cart') {
      const config = {
        purchaserId: Number(this.$route.query.purchaserId),
        previewItems: [],
      }
      let selectedCustomRowKeys = this.$route.query.custom && this.$route.query.custom.split('|') || []
      let selectedRowKeys = this.$route.query.selectedRowKeys && this.$route.query.selectedRowKeys.split('|') || []
      const custom = []
      selectedCustomRowKeys.forEach(item => {
        if (item && item != -1) {
          custom.push({shoppingCartIds: item.split(',')})
        }
      })
      selectedRowKeys = selectedRowKeys.map(item => {
        let i = item.split(',').filter(i => i && i)
        return {
          shoppingCartIds: i
        }
      })
      config.previewItems = [...custom, ...selectedRowKeys]
      this.cartConfig = config
      console.log(66777777, this.cartConfig);
    }
    this.getconfirm()
    this.getdefaults()
    dsPur(this.$route.query.purchaserId).then(res => {
      if (res.code === 200) {
        this.dsList = res.data
      }
    })
  },
  filters: {
    unit: (val) => type[val],
    unitText(val) {
      if (val === 0) {
        return '增值税 0%：'
      }
      if (val) {
        return `增值税 ${Math.round(val * 1000000) / 10000}%：`
      }
      return ''
    }
  },
  methods: {
    showModal() {
      if (this.updateinfo.tagAddr === '') {
        this.$message.error('请先添加地址')
      } else this.amendfill = true
    },
    handleCancel1() {
      this.getList()
    },
    amend() {
      this.amendinfo = false
      this.getconfirm()
    },
    //修改信息按钮
    // 修改发票信息
    invoiceOk() {
      if (!this.verify(true)) return
      updateinvoice({
        entityCharacter: 'P',
        ...this.purchaserInvoiceInfo,
      }).then((res) => {
        if (res.code === 200) {
          this.amendinfo = false
          this.getconfirm()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //获取页面数据
    getconfirm() {
      this.spinTop = true;
      (this.$route.query.type == 'cart' ? cartPreview(this.cartConfig) : confirm(this.confirm)).then((res) => {
        this.spinTop = false
        if (res.code === 200) {
          // 所有订单数据（增值税电子普通发票）
          const data = []
          res.data.orderPreviewItems.forEach(item => {
            item.remark = ''
            item.vatRate = item.merchandises[0].vatRate
            item.merchandises.forEach(i => {
              data.push({ ...i, unitsMeasure: item.unitsMeasure })
            })
          });
          this.indexInfo = res.data
          this.purchaserInvoiceInfo = { ...res.data.purchaserInvoiceInfo }
          this.data_ = res.data.orderPreviewItems
          this.dataInvoice = [...data,
          { config: 'space' },
          { config: 'total', value: (this.indexInfo.totalAmount || 0).toFixed(2) },
          { config: 'upperCase', value: convertCurrency(this.indexInfo.totalAmount) },]
          this.bord2 = true
        } else {
          this.$message.error(res.message)
        }
      })
      // 获取地址列表
      list({
        purchaserId: this.$route.query.purchaserId
      }).then((res) => {
        this.listAddress = res.data
        if (res.code === 200) {
          this.bord = true
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取地址列表
    getList() {
      list({
        purchaserId: this.$route.query.purchaserId
      }).then((res) => {
        this.listAddress = res.data
        if (res.code === 200) {
          const showAddress = this.listAddress.find((item) => {
            return item.id === this.pitchs
          })
          this.updateinfo = { ...this.updateinfo, ...showAddress }
          this.checked = !!this.updateinfo.isDefault
          this.amendfill = false
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取默认地址
    getdefaults() {
      getdefault({
        purchaserId: this.$route.query.purchaserId
      }).then((res) => {
        if (!res.code === 200) {
          this.$message.error(res.message)
        }
        // else {
        //   res.data
        //     ? (this.updateinfo.addrId = res.data.id)
        //     : (this.updateinfo.addrId = undefined)
        // }
        if (!res.data) {
          this.updateinfo = {
            addrId: undefined,
            provinceCode: '',
            areaCode: '',
            cityCode: '',
            detailAddr: '',
            tagAddr: ''
          }
        } else {
          !this.pitchs &&
            (this.updateinfo = { ...this.updateinfo, ...res.data }) &&
            (this.pitchs = res.data.id)
          this.checked = !!this.updateinfo.isDefault
        }
      })
    },
    //删除地址
    del(id) {
      deleteAddress({ addrId: id }).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.getconfirm()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 修改地址
    amendAddress() {
      if (!this.updateinfo.tagAddr) {
        this.$message.error('请填写一个地址标签')
      } else if (!this.updateinfo.provinceCode) {
        this.$message.error('请选择一个省市区')
      } else if (!this.updateinfo.detailAddr) {
        this.$message.error('请填写一个详细地址')
      } else
        return updateAddress({
          ...this.updateinfo,
          addrId: this.updateinfo.id
        }).then((res) => {
          if (res.code === 200) {
            this.getList()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    pitch(e) {
      this.pitchs = e.id
    },
    // 切换地址
    save() {
      if (this.listAddress == '') {
        this.updateinfo = {
          addrId: undefined,
          provinceCode: '',
          areaCode: '',
          cityCode: '',
          detailAddr: '',
          tagAddr: ''
        }
        return
      }
      const showAddress = this.listAddress.find((item) => {
        return item.id === this.pitchs
      })
      showAddress.addrId = this.pitchs
      this.updateinfo = { ...this.updateinfo, ...showAddress }
      this.checked = !!this.updateinfo.isDefault
      this.switchfill = false
      this.getconfirm()
    },
    // 设置默认地址
    switchAddress(id) {
      setdefault({ addrId: id }).then((res) => {
        if (res.code === 200) {
          this.getconfirm()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 添加地址
    addAddress() {
      if (!this.addinfo.tagAddr) {
        this.$message.error('请填写一个地址标签')
      } else if (!this.addinfo.provinceCode) {
        this.$message.error('请选择一个省市区')
      } else if (!this.addinfo.detailAddr) {
        this.$message.error('请填写一个详细地址')
      } else
        return createAddress({ ...this.addinfo, isDefault: this.checkedAdd ? 1 : 0 }).then((res) => {
          if (res.code === 200) {
            this.getList()
            this.getdefaults()
            this.addinfo = {
              purchaserId: this.$route.query.purchaserId,
              provinceCode: '', //省份编码
              areaCode: '', //区域编码
              cityCode: '', //城市编码
              detailAddr: '', //详情地址
              tagAddr: '' //地址标签
            }
            this.$refs.Cascader.sValue = []
            this.addfill = false
            this.checkedAdd = false
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    ChangeAmend(value) {
      this.updateinfo.provinceCode = value[0]
      this.updateinfo.cityCode = value[1]
      this.updateinfo.areaCode = value[2]
    },
    onChange(value) {
      this.addinfo.provinceCode = value[0]
      this.addinfo.cityCode = value[1]
      this.addinfo.areaCode = value[2]
    },
    // 生成订单
    produceOrder() {
      if (!this.verify()) return
      this.loading = true
      if (this.$route.query.type == 'cart') {
        const config = JSON.parse(JSON.stringify(this.cartConfig))
        // 从购物车进入-生成订单流程
        config.downstreamId = this.confirm.downstreamId
        config.deliveryAddressId = this.pitchs
        this.data_ 
        config.shoppingCarts = config.previewItems.map((item,index) => {
          return {
            ...item,
            orderRemark: this.data_[index].remark
          }
        })
        delete config.previewItems
        cartCreate(config).then(res => {
          this.loading = false
          if (res.code === 200) {
            this.$store.dispatch('cartAmount', 1)
            this.$router.replace({
              path: '/succee-order',
              query: {
                toPath: '/buyercenter/order-management',
                purchaserId: this.$route.query.purchaserId
              }
            })
          } else {
            this.$message.error(res.message)
          }
        })
        return
      }
      createOrder({
        ...this.confirm,
        deliveryAddressId: this.pitchs
      })
        .then((res) => {
          this.loading = false
          if (res.code === 200) {
            this.$router.replace({
              path: '/succee-order',
              query: {
                id: res.data,
                purchaserId: this.$route.query.purchaserId
              }
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((r) => {
          this.$message.error(r.message)
        })
    },
    verify(b) {
      if (!this.updateinfo.tagAddr && !b) {
        this.$message.error('请先添加收货地址')
      } else if (!this.updateinfo.fullAddr && !b) {
        this.$message.error('请先填写收货地址')
      } else if (!this.purchaserInvoiceInfo.invoiceAddr) {
        this.$message.error('请填写发票地址')
      } else if (!this.purchaserInvoiceInfo.invoiceMobile) {
        this.$message.error('请填写正确的发票电话')
      } else if (!this.purchaserInvoiceInfo.invoiceBankName) {
        this.$message.error('请填写发票银行名称')
      } else if (!this.purchaserInvoiceInfo.invoiceBankAcc) {
        this.$message.error('请填写发票银行账户')
      } else if (!regEmail.test(this.purchaserInvoiceInfo.invoiceEmail)) {
        this.$message.error('请填写正确的电子发票邮箱')
      } else return true
    },
    verify2() {
      if (!this.indexInfo.purchaserInvoiceInfo.invoiceBankAcc) {
        this.invoiceText = '请补充完整的信息'
      } else if (!this.indexInfo.purchaserInvoiceInfo.invoiceMobile) {
        this.invoiceText = '请补充完整的信息'
      } else if (!this.indexInfo.purchaserInvoiceInfo.invoiceBankName) {
        this.invoiceText = '请补充完整的信息'
      } else if (!this.indexInfo.purchaserInvoiceInfo.invoiceEmail) {
        this.invoiceText = '请补充完整的信息'
      } else if (!this.indexInfo.purchaserInvoiceInfo.invoiceAddr) {
        this.invoiceText = '请补充完整的信息'
      }
      else return true
    }
  }
}
</script>
<style lang="less" scoped>
.fillOrder {
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px) {
    padding: 0 15px;
  }
  margin: auto;
  .fillOrder-title {
    line-height: 80px;
    font-size: 16px;
    font-weight: 400;
  }
  .mainbody {
    background-color: #ffffff;
    padding: 10px 50px;
    font-size: 14px;
    /deep/ .ant-card-bordered {
      border: 1px solid transparent;
    }
    /deep/.ant-card-body {
      padding: 0;
    }
    .lh60 {
      font-size: 16px;
      font-weight: 400;
      color: #666;
      line-height: 50px;
    }
    .mt42 {
      margin-top: 42px;
    }
    .bd1 {
      padding: 30px;
      border: 1px solid #ccc;
    }
    .address {
      width: 560px;
      line-height: 32px;
    }
    .addr {
      .addr-text {
        cursor: pointer;
        color: #60a830;
      }
    }
    .line {
      padding-left: 60px;
      height: 80px;
      border-right: 1px solid #ccc;
    }
    .switch-address {
      padding-left: 48px;
    }
    .lh22 {
      line-height: 32px;
    }
    .lh40 {
      line-height: 40px;
      font-size: 16px;
    }
    .amend-info {
      width: 60px;
      padding: 0 0 0 110px;
      color: royalblue;
      cursor: pointer;
    }
    .inv-text {
      padding: 30px 0 50px 30px;
    }
    .goods-info {
      border: 1px solid rgb(232, 232, 232);
    }
    .red {
      color: red;
    }
  }
  .invoice-info {
    // height: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      width: 900px;
      display: flex;
      justify-content: space-between;
      p {
        width: 350px;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .create-order {
    // background-color: rgba(249, 249, 249, 1);
    // padding: 10px 30px;
    // border: 1px solid rgba(228, 228, 228, 1);
    // line-height: 28px;
    // margin-bottom: 50px;
    // font-size: 14px;
    font-weight: 700;
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-size: 16px;
    padding: 10px 30px;
    padding-bottom: 54px;
    // height: 100px;
    color: #000000;
    background: #fff;
    // border-top: 1px solid rgba(184, 116, 26, 1);
    // border-bottom: 1px solid rgba(184, 116, 26, 1);
    margin-bottom: 100px;
    position: relative;
    // button {
    //   position: absolute;
    //   bottom: -45px;
    //   right: 0;
    // }
    .btn-w {
      width: 180px;
    }
  }
  .s-total {
    margin-block: 12px;
    /deep/ .ant-col {
      width: 14%;
      text-align: center;
    }
  }
  .t-total {
      font-weight: 700;
      font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
      font-size: 16px;
      color: #000000;
      // position: absolute;
      // left: -100%;
      // bottom: 0;
      // width: 100%;
      // text-align: right;
      // line-height: 24px;
    }
  .c-total {
    display: inline-block;
    font-weight: 700;
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-size: 24px;
    color: #ff0000;
    line-height: 28px;
    position: relative;
    .t-total {
      font-weight: 700;
      font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
      font-size: 16px;
      color: #000000;
      position: absolute;
      left: -100%;
      bottom: 0;
      width: 100%;
      text-align: right;
      line-height: 24px;
    }
  }
}
.invaddr {
  p {
    margin-left: 70px;
  }
  .invspan {
    float: left;
  }
}
.ress {
  width: 530px;
  height: 110px;
  border: 1px solid #ccc;
  padding: 30px;
  margin-top: 20px;
  transition: all 0.2s;
}
.fw {
  font-weight: 600;
}
.scrollY {
  height: 510px;
  overflow-y: auto;
}
.color999 {
  color: #999999;
}
.fs16 {
  font-size: 16px;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt20 {
  padding-top: 20px;
}
.pb20 {
  padding-bottom: 20px;
}
.border-t {
  padding-left: 16px;
  padding-right: 16px;
}
.mb12 {
  margin-bottom: 12px;
}
.cursor {
  cursor: pointer;
}
.warn {
  border: 1px solid red !important;
}
/deep/.ant-modal-title {
  text-align: center;
}
/deep/ .ant-table-placeholder {
  display: none;
}
.bordred {
  border: 1px solid red;
}
// .remark {
//   margin-top: 24px;
//   font-size: 15px;
//   span {
//     margin-top: 2px;
//   }
// }

.p-table-title {
  display: flex;
  & > div {
    border-bottom: 2px solid rgba(128, 128, 255, 1);
    // margin-right: 5px;
    text-align: center;
    padding: 15px 0;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      background-color: #fff;
      right: 0px;
      bottom: -2px;
    }
    &:last-child:after {
      height: 0;
    }
  }
  .p-name {
    width: 20%;
  }
  .bill-name {
    width: 17%;
  }
  .p-type {
    width: 13%;
  }
  .p-price {
    width: 8%;
  }
  .p-number {
    width: 8%;
  }
  .p-tax {
    width: 10%;
  }
  .p-sub {
    flex: 1;
    margin-right: 0;
  }
}
/deep/ .ant-table-footer {
  background: rgba(192, 255, 255, 1);
  padding: 10px 0;
}
.table-footer {
  display: flex;

  .remark {
    width: 50%;
    display: flex;
    padding: 0 10px;
    .remark-label {
      width: 50px;
    }
  }
  .f-total {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // .total-text{
    //   margin-top: 10px;
    // }
  }
  .total-pic {
    padding-bottom: -20px;
    width: 14%;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: red;
    font-weight: bold;
  }
}

.p_table {
  .p_sub_text {
    color: red;
    font-weight: bold;
  }
  /deep/ .ant-table-thead > tr > th {
    background: #fff;
    border-bottom: 1px solid rgba(159, 255, 255, 1);
  }
  /deep/ .ant-table-tbody > tr > td {
    // background: #fff;
    background-color: rgba(217, 255, 255, 1);
    border-bottom: 1px solid rgba(159, 255, 255, 1);
  }
  /deep/.ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background-color: rgba(217, 255, 255, 1);
  }
  .square {
    // float: left;
    background-color: #fff;
  }
  // .goods-name {
    // margin-left: 60px;
    // display: flex;
    // align-items: center;
  // }
}

/deep/ .ant-table-bordered .ant-table-body > table {
  border: 1px solid #a30014;
  border-right: 0;
  border-bottom: 0;
  color: #a30014;
}

.w140 {
  width: 150px;
  height: 40px;
  color: #999;
  background: rgb(245, 245, 245);
  border: none;
  &:hover {
    color: rgb(51, 51, 51);
  }
}

.invoice-title {
  font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
  color: #666666;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 6px;
  text-align: center;
  line-height: 36px;
  display: inline-block;
  border-bottom: 5px double #797979;
}
.invoiceTable {
  margin-top: 16px;
  margin-bottom: 42px;
  /deep/ .ant-table-small {
    border: none;
  }
  /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
    margin: auto;
  }
  /deep/.ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: transparent;
  }
  /deep/ .ant-table-header-column {
    display: inline !important;
  }
  /deep/ .ant-table-tbody {
    background: #fff;
  }
  /deep/ .ant-table-bordered .ant-table-body > table {
    border: 1px solid #a30014;
    border-right: 0;
    border-bottom: 0;
    color: #a30014;
  }
  /deep/ .ant-table-thead > tr > th {
    color: #a30014;
    background: #fff;
    border-right: 1px solid #a30014 !important;
    border-bottom: 1px solid #a30014;
  }
  /deep/ .ant-table-thead > tr {
    &:nth-child(2) > th {
      border-bottom: none;
    }
  }
  /deep/ .ant-table-tbody > tr > td {
    border-bottom: none;
    border-right: 1px solid #a30014 !important;
  }
  /deep/ .ant-table-row-level-0 {
    &:last-child > td {
      border-bottom: 1px solid #a30014;
    }
    &:nth-last-child(2) > td {
      border-bottom: 1px solid #a30014;
    }
    &:nth-last-child(3) > td {
      border-bottom: 1px solid #a30014;
    }
  }
  .table-row {
    padding: 0 4px;
    text-align: left;
    color: #666;
    span {
      margin-right: 8px;
      color: #a30014;
    }
    .table-col {
      position: relative;
      button {
        position: absolute;
        bottom: -50px;
        left: 0;
        z-index: 100;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(228, 228, 228, 1);
      }
    }
    /deep/ .ant-col {
      line-height: 24px;
    }
  }
  /deep/ .table-list {
    color: #666666;
  }
  /deep/ .font-foot {
    color: #a30014;
    text-align: left !important;
  }
}
</style>